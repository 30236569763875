<template>
    <div class="sceneryCateList">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>礼券列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="search">
        <span>礼券名称</span>
        <el-input v-model="list.prodName" placeholder="请输入礼券名称"></el-input>
        <span style="margin-right: 20px">状态</span>
        <el-select
          v-model="list.status"
          placeholder="请选择"
          style="margin-right: 20px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span style="margin-right: 20px">商品类型</span>
        <el-select
          v-model="list.prodType"
          placeholder="请选择"
          style="margin-right: 20px"
        >
          <el-option
            v-for="item in optionsType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="getSceneryCateList()">查找</el-button>
        <el-button
          type="primary"
          @click="onAddCate(0)"
          v-if="$store.state.powerList.indexOf('scenery:type:list:insert') !== -1"
          >添加</el-button
        >
        <el-button
          type="primary"
          @click="$router.go(-1)"
        
          >返回</el-button
        >
      </div>
      <el-table :data="tableData" border>
        <!-- <el-table-column align="center" prop="dept" label="排序">
          <template slot-scope="{ row }">
            <el-input
              class="input-new-tag"
              v-if="row.inputVisible"
              v-model="row.dept"
              ref="saveTagInput"
              size="small"
              @input="onInput"
              @keyup.enter.native="handleInputConfirm(row)"
              @blur="handleInputConfirm(row)"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="onChangeSort(row)"
              >{{ row.dept }}</el-button
            >
          </template>
        </el-table-column> -->
        <el-table-column align="center" prop="packProdId" label="ID">
        </el-table-column>
        <el-table-column align="center" prop="packProdName" label="礼券名称">
        </el-table-column>
        <el-table-column align="center" prop="packName" label="所属礼包">
        </el-table-column>
        <el-table-column align="center" prop="showImg" label="展示图">
          <template slot-scope="{ row }">
            <el-image
              class="showimg"
              :preview-src-list="[row.showImg]"
              :src="row.showImg"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="prodType" label="商品类型">
        </el-table-column>
        <el-table-column align="center" prop="prodName" label="商品名称">
        </el-table-column>
        <el-table-column align="center" prop="prodFee" label="售价">
        </el-table-column>
        <el-table-column align="center" prop="endFee" label="结算价">
        </el-table-column>
        <el-table-column align="center" prop="count" label="数量">
        </el-table-column>
        <el-table-column align="center" prop="strStatus" label="状态">
        </el-table-column>
        <el-table-column align="center" prop="" label="操作" width="300">
          <template slot-scope="{ row }">
            <el-button
              size="mini"
              v-if="row.status === 2"
              type="primary"
              @click="changeStatus(row.packProdId, 1)"
              >上架</el-button
            >
            <el-button
              size="mini"
              v-if="row.status === 1"
              type="primary"
              @click="changeStatus(row.packProdId, 2)"
              >下架</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="delCate(row.packProdId)"
              style="margin: 5px"
              v-if="
                $store.state.powerList.indexOf('pack:prod:list:delete') !== -1
              "
              >删除</el-button
            >
            
              
            <el-button
              size="mini"
              type="primary"
              @click="onEditCalsssA(row)"
              v-if="$store.state.powerList.indexOf('pack:prod:list:edit') !== -1"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="primary"
              v-if="row.prodType != '美食'"
              @click="onSetPrice(row.packProdId)"
              >设置价格</el-button
            >
            <el-button
              size="mini"
              type="primary"
              v-show="row.prodType != '精选商城' && row.prodType != '万旅卡' && row.prodType != '美食' && row.prodType != '门票'"
              v-if="$store.state.powerList.indexOf('gift:cate:list:add') !== -1"
              @click="onIsUpgrade(row.packProdId)"
              >升级设置</el-button
            >
            <el-button
              size="mini"
              type="primary"
              v-if="$store.state.powerList.indexOf('pack:prod:list:log') !== -1"
              @click="$router.push(`/giftPackageViewLog?packProdId=${row.packProdId}`)"
              >查看日志</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="list.currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="list.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
  </template>
    <script>
  //   import {
  //     sceneryCate,
  //     addSceneryCate,
  //     changeSort,
  //     editSceneryCate,
  //     delSceneryCate,
  //     changeStatus,
  //   } from "../../../api/sceneryCate";
  import {
    getPackProdList,
    modifyPackProdStatus,
    deletePackProd,
    selectLogGiftCate,
    getPresaleListUp
  } from "../../../api/seckillInfo";
  //   import { sendFile } from "../../api/sendFile";
  import SendImage from "../../../components/sendImage.vue";
  export default {
    name: "sceneryCateList",
    components: {
      SendImage,
    },
    data() {
      return {
        // addClassADialogVisible: false,
        // editClassADialogVisible: false,
        // addClassAForm: {
        //   cateName: "",
        //   showImg: "",
        //   cateId: 0,
        // },
        // editClassAForm: {
        //   cateName: "",
        //   cateId: null,
        //   showImg: "",
        // },
        list: {
          currentPage: 1,
          pageSize: 5,
          prodName: "",
          status: 0,
          prodType:0,
          packId:''
        },
        tableData: [],
        pagination: {},
        options: [
          {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "上架",
          },
          {
            value: 2,
            label: "下架",
          },
        ],
        optionsType:[
        {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "客房",
          },
          {
            value: 2,
            label: "门票",
          },
          {
            value: 3,
            label: "线路套餐",
          },
          {
            value: 4,
            label: "万旅卡",
          },
          {
            value: 5,
            label: "美食",
          },
          {
            value: 6,
            label: "精选商城",
          },
        ],
        logList: [],
        viewLogDialogVisible: false,
      };
    },
    created() {
        this.list.packId = this.$route.query.packId
      this.getSceneryCateList();
    },
    methods: {
      changeStatus(prodPackId, status) {
        this.$confirm("是否操作这条数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const { data } = await modifyPackProdStatus({
                prodPackId,
              status,
            });
            if (data.code == 0) {
              this.$message({
                type: "success",
                message: data.msg,
              });
              this.getSceneryCateList();
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      },
  
      onInput(value) {
        var reg = /^[0-9]*[1-9][0-9]*$/;
        var reg2 = /^-[0-9]*[1-9][0-9]*$/;
        if (!reg.test(value) && !reg2.test(value)) {
          return this.$message({
            type: "warning",
            message: "请输入整数!",
          });
        }
      },
  
      delCate(prodPackId) {
        this.$confirm("是否删除这个分类?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const { data } = await deletePackProd({
                prodPackId,
            });
            console.log(data);
            if (data.code == 0) {
              this.getSceneryCateList();
              this.$message({
                type: "success",
                message: data.msg,
              });
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      },
      async viewLog(cateId) {
        const { data } = await selectLogGiftCate({
          cateId,
        });
        if (data.code == 0) {
          this.logList = data.data;
          this.viewLogDialogVisible = true;
        }
      },
      onEditCalsssA(row) {
        this.$router.push("/addGiftPackage?packId=" + this.list.packId+'&packProdId='+row.packProdId);
      },
      onChangeSort(row) {
        row.inputVisible = true;
      },
      async getSceneryCateList() {
        const { data } = await getPackProdList(this.list);
        this.pagination = data.pagination;
        // data.list.map((item) => {
        //   item.inputVisible = false;
        // });
        this.tableData = data.list;
      },
      onAddCate() {
        this.$router.push("/addGiftPackage?packId=" + this.list.packId);
      },
      //上传图片
      addImageStr(image, type) {
        if (type === 1) {
          this.addClassAForm.showImg = image;
        } else if (type === 2) {
          this.editClassAForm.showImg = image;
        }
      },
      handleSizeChange(num) {
        this.list.pageSize = num;
        this.getSceneryCateList();
      },
      handleCurrentChange(num) {
        this.list.currentPage = num;
        this.getSceneryCateList();
      },
      onSetPrice(id) {
      this.$router.push(`/giftPackagePrice/${id}/${this.list.packId}`);
    },
    async onIsUpgrade(packProdId) {
    //   const { data } = await getPresaleListUp({
    //     presaleId,
    //   });
    //   if (data.code == 0) {
    //     this.$message.success(data.msg);
    //     this.$router.push(`/giftPackageUpgradeList/${presaleId}`);
    //   } else {
    //     this.$message.error(data.msg);
    //   }
    this.$router.push(`/giftPackageUpgradeList/${packProdId}`);
    },
    },
  };
  </script>
    <style lang="less" scoped>
  .sceneryCateList {
    .el-breadcrumb {
      height: 35px;
      border-bottom: 1px solid rgb(192, 191, 191);
    }
    .search {
      margin-top: 50px;
      .el-input {
        width: 200px;
        margin: 0 20px;
      }
    }
    .el-table {
      margin-top: 50px;
      .el-table__body-wrapper {
        .el-table__body {
          tbody {
            td {
              .showimg {
                width: 80px;
                height: 80px;
              }
            }
          }
        }
      }
    }
    /deep/.addDialog,
    /deep/ .editDialog {
      .el-dialog__body {
        .el-form {
          .el-form-item__content {
            display: flex;
            span {
              color: red;
            }
            .img {
              position: relative;
              height: 100px;
              width: 100px;
              margin: 0 20px;
              img {
                height: 100px;
                width: 100px;
                border: 1px solid #c0ccda;
              }
              div {
                position: absolute;
                top: 0;
                right: 5px;
                height: 10px;
                width: 10px;
                z-index: 100;
              }
            }
            .addImg {
              display: inline-block;
              width: 100px;
              height: 100px;
              border: 1px dashed #c0ccda;
              text-align: center;
              line-height: 100px;
              cursor: pointer;
              i {
                width: 50px;
                height: 50px;
                font-size: 20px;
                color: #c0ccda;
              }
            }
          }
        }
      }
      .log{
          margin-top: 20px;
      }
      .log:first-child{
          margin-top:0px
      }
    }
    .el-pagination {
      margin-top: 50px;
      float: right;
    }
  }
  </style>